import React, {useState, useEffect, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useSelector} from 'react-redux';
import CheckForm from '../components/Check/CheckForm';
import CheckResults from '../components/Check/CheckResults';

const Check = () => {
    const user = useSelector((state) => state.login.user);
    const location = useLocation();
    const [cardId, setCardId] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [cardData, setCardData] = useState(null);
    const [isUserNotFound, setIsUserNotFound] = useState(false);
    const [isCardNotFound, setIsCardNotFound] = useState(false);
    const [institutionData, setInstitutionData] = useState();
    const [institutionId, setInstitutionId] = useState('');

    const handleCardIdChange = (event) => {
        const value = event.target.value;
        if (!/^\d*$/.test(value)) {
            setIsUserNotFound(true);
        } else {
            setIsUserNotFound(false);
            setCardId(value);
        }
    };

    const handleCloseSnackbar = () => {
        setIsUserNotFound(false);
    };

    const handleCloseCardNotFoundSnackbar = () => {
        setIsCardNotFound(false);
    };

    const fetchMedioInstitutionId = useCallback((user) => {
        return fetch(`https://app.kvery.io/query/api/120da4fb5c6/check-medio-id?hash=${user.user_hash}`)
            .then(response => response.json())
            .then(data => {
                if (data?.status === 1 && data?.response?.length > 0) {
                    setInstitutionId(data.response[0].medio_id);
                } else {
                    setInstitutionId(null);
                }
            })
            .catch(error => {
                console.error('Hiba történt a fetch közben:', error);
                setInstitutionData(null);
            });
    }, []);

    useEffect(() => {
        if (user && user.length > 0) {
            fetchMedioInstitutionId(user[0]);
        }
    }, [user, fetchMedioInstitutionId]);

    const fetchInstitutionData = useCallback(() => {
        return fetch(`https://app.kvery.io/query/api/120da4fb5c6/1690809051vHdP39z6XWIQGY8xZTqVxa?institutionId=${institutionId}`)
            .then(response => response.json())
            .then(data => {
                if (data?.status === 1 && data?.response?.length > 0) {
                    setInstitutionData(data.response[0]);
                } else {
                    setInstitutionData(null);
                }
            })
            .catch(error => {
                console.error('Hiba történt a fetch közben:', error);
                setInstitutionData(null);
            });
    }, [institutionId]);

    const fetchCardData = useCallback(() => {
        return fetch('https://app.kvery.io/query/api/120da4fb5c6/validate/card', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cardId: cardId,
                user_hash: user[0]?.user_hash,
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data?.response?.length > 0) {
                    setCardData(data.response[0]);
                    setShowResults(true);
                } else if (data?.status === 0 && data.response === '') {
                    setIsCardNotFound(true);
                    setShowResults(false);
                } else {
                    setShowResults(false);
                }
            })
            .catch(error => {
                console.error(error);
                setShowResults(false);
            });
    }, [cardId, user]);


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const urlCardId = params.get('cardId');
        if (urlCardId) {
            setCardId(urlCardId);
        }
    }, [location.search]);

    useEffect(() => {
        if (cardId) {
            Promise.all([fetchInstitutionData(), fetchCardData()]);
        }
    }, [fetchInstitutionData, fetchCardData, cardId]);

    return (
        <>
            <Container sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '25px',
                marginBottom: '25px',
                justifyContent: 'center',
                minHeight: '70vh'
            }}>
                <Box
                    className='checkResult'
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        bgcolor: '#fff',
                        width: '550px',
                        minHeight: '350px',
                        borderRadius: '8px',
                        justifyContent: 'center',
                        alignContent: 'center',
                        padding: '25px'
                    }}>
                    {showResults ? (
                        <div style={{display: 'flex', flexDirection: 'column', width: '80%'}}>
                            <CheckResults cardData={cardData} institutionData={institutionData}/>
                        </div>
                    ) : (
                        <CheckForm cardId={cardId} handleCardIdChange={handleCardIdChange}
                                   handleCheckClick={fetchCardData}/>
                    )}
                </Box>
            </Container>
            <Snackbar open={isUserNotFound} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
                    Csak numerikus karakter használható!
                </MuiAlert>
            </Snackbar>
            <Snackbar open={isCardNotFound} autoHideDuration={5000} onClose={handleCloseCardNotFoundSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseCardNotFoundSnackbar} severity="error">
                    Nem található kártyaszám!
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default Check;
