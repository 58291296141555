import React from 'react';

const Exceptions = ({clubExceptions}) => {
    return (
        <div className="discountRowLast">
            <div className="discountTab" style={{color: '#ED535C', marginBottom:'15px'}}>
                Kivételek:
            </div>
            <div className='discountRes'>
                {clubExceptions}
            </div>
        </div>
    );
};


export default Exceptions;