import React from 'react';
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Lottie from 'react-lottie';
import AnnouncementAnimation from '../announcement_animation.json';

function AnnouncementModal({ modalOpen, setModalOpen, unreadMessages, handleReadMessage }) {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: AnnouncementAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div className='announcementModal'>
                    {unreadMessages.map((message) => (
                        <div key={message.id} style={{ textAlign: 'center' }}>
                            <Lottie
                                options={defaultOptions}
                                height={150}
                                width={150}
                            />
                            <h2>{message.title}</h2>
                            <p style={{textAlign:'left'}}>{message.content}</p>
                            <Button variant="outlined" color="primary" onClick={() => handleReadMessage(message.id)}>Elolvastam</Button>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
}

export default AnnouncementModal;
