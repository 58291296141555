import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import Button from "@mui/material/Button";

const columns = [
    {field: "id", headerName: "ID", flex: 1},
    {field: "email", headerName: "E-mail", flex: 1},
    {field: "created_at", headerName: "Létretjött", flex: 1},
];

const OffersStatisticsTable = ({data}) => {
    return (
        <div style={{minHeight: 400, width: "100%"}}>
            <p className="boxTitle">Ajánlottak:</p>
            <DataGrid rows={data} columns={columns} pageSize={10}/>
        </div>
    );
};

export default OffersStatisticsTable;
