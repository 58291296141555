import React from 'react';

const CheckTable = ({ label, value }) => {
    return (
        <div className='checkTable'>
            <div className="discountTab">
                {label}:
            </div>
            <div className='discountRes'>
                {value}
            </div>
        </div>
    );
};

export default CheckTable;