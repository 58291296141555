import React, { useState } from 'react';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EmailSendSuccessFully from "../components/EmailSendSuccessFully";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import { useSelector } from "react-redux";
import Lottie from 'react-lottie';
import AlertAnimation from "../alert_animation.json";


const CardProposal = () => {
    const user = useSelector((state) => state.login.user[0]); // Access user data from Redux state
    const [showOtherComponent, setShowOtherComponent] = useState(false);
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const lowercaseEmail = email.toLowerCase();

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: AlertAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    // Modal megnyitása
    const handleOpenModal = () => {
        if (isValidEmail(email)) {
            setOpenModal(true);
        } else {
            setIsError(true);
        }
    };

    // Modal bezárása
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    // Az e-mail elküldése és a sikeres üzenet megjelenítése
    const handleConfirmEmail = () => {
        // Prepare the data to be sent in the request body
        const data = {
            email: email,
            user_hash: user.user_hash, // Access the user_hash from the Redux state directly
        };

        // Make the POST request
        fetch('https://app.kvery.io/query/api/120da4fb5c6/offer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Handle the successful response here (if needed)
                // For example, you can show a success message or update the state.
                setShowOtherComponent(true); // Show the success component
            })
            .catch((error) => {
                console.error('Error making the request:', error);
                // Handle the error here (if needed)
                setIsError(true); // Show the error Snackbar
            });

        setOpenModal(false); // Close the modal after making the request
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleCloseSnackbar = () => {
        setIsError(false); // Értesítés bezárása
    };

    const isValidEmail = (email) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
    };

    return (
        <>
            <Container sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '25px',
                marginBottom: '25px',
                justifyContent: 'center',
                minHeight: '70vh'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    bgcolor: '#fff',
                    minHeight: '300px',
                    width: '460px',
                    borderRadius: '8px',
                    justifyContent: 'center',
                    alignContent: 'center'
                }}>
                    {!showOtherComponent && (
                        <>
                            <p className="boxTitle">Adja meg az e-mail címet:</p>
                            <TextField id="standard-basic" label="Ajánlott e-mail címe" variant="standard" value={lowercaseEmail} onChange={handleEmailChange} />
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between', width: '80%' }}>
                                <Button style={{ marginTop: '25px', width: '45%' }} variant="outlined" onClick={() => { window.location.href = '/'; }}>Vissza</Button>
                                <Button style={{ marginTop: '25px', width: '45%' }} variant="contained" onClick={handleOpenModal}>Tovább</Button>
                                <Modal open={openModal} onClose={handleCloseModal}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        position: 'absolute',
                                        bgcolor: 'background.paper',
                                        borderRadius: '8px',
                                        padding: '20px',
                                        width: '360px'
                                    }}>
                                        <Lottie
                                            options={defaultOptions}
                                            height={100}
                                            width={100}
                                        />
                                        <p style={{fontWeight:'bold', margin:'0px'}}>Biztosan elküldi erre az e-mail címre?</p>
                                        <p style={{marginBottom:'30px'}}>{email}</p>
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between', width: '100%' }}>
                                            <Button variant="outlined" onClick={handleCloseModal}>Mégse</Button>
                                            <Button variant="contained" onClick={handleConfirmEmail}>Megerősítem</Button>
                                        </div>
                                    </Box>
                                </Modal>
                            </div>
                        </>
                    )}
                    {showOtherComponent && <EmailSendSuccessFully />}
                </Box>
            </Container>

            <Snackbar open={isError} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
                    A megadott e-mail cím nem megfelelő!
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default CardProposal;
