import React from 'react';
import TextField from '@mui/material/TextField';

const LoginForm = ({ email, setEmail }) => {
    const handleEmailChange = (e) => {
        // Convert the input value to lowercase and set it as the email state
        setEmail(e.target.value.toLowerCase());
    };

    return (
        <>
            <TextField
                id="standard-basic"
                helperText="E-mail cím"
                variant="standard"
                value={email}
                type="email"
                onChange={handleEmailChange} // Use the new event handler
            />
        </>
    );
};

export default LoginForm;
