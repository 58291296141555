import React, {useState} from 'react';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import StepOneContent from '../components/Sales/StepOneContent';
import StepFourContent from '../components/Sales/StepFourContent';
import ConfirmationModal from '../components/Sales/ConfirmationModal';
import {useSelector} from 'react-redux';
import EmailSendSuccessFully from "../components/EmailSendSuccessFully";

const Sales = () => {
    const user = useSelector((state) => state.login.user[0]);
    const [activeStep, setActiveStep] = useState(0);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [coupon, setCoupon] = useState('');
    const [confirmationFinalized, setConfirmationFinalized] = useState(false);

    const isStepOneComplete = email.trim() !== '' && phone.trim() !== '';

    const handleNext = () => {
        setActiveStep(1);
    };

    const handleConfirmationOpen = () => {
        setOpenConfirmation(true);
    };

    const handleConfirmationClose = () => {
        setOpenConfirmation(false);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePhoneChange = (newValue) => {
        setPhone(newValue);
    };
    const handleFinalize = () => {
        setConfirmationFinalized(true);
        handleConfirmationClose();
    };

    const handleCouponChange = (newCoupon) => {
        setCoupon(newCoupon);
    };


    return (
        <Container sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '25px',
            marginBottom: '25px',
            justifyContent: 'center',
            minHeight: '70vh'
        }}>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                bgcolor: '#fff',
                width: '460px',
                borderRadius: '8px',
                justifyContent: 'center',
                alignContent: 'center',
                padding: '25px'
            }}>
                {confirmationFinalized ? (
                    <EmailSendSuccessFully/>
                ) : (
                    <>
                        {activeStep === 0 && (
                            <>
                                <StepOneContent
                                    email={email}
                                    phone={phone}
                                    onEmailChange={handleEmailChange}
                                    onPhoneChange={handlePhoneChange}
                                    onCouponChange={handleCouponChange}
                                />
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                <StepFourContent email={email} phone={phone} coupon={coupon} />
                            </>
                        )}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <Button
                                variant="outlined"
                                style={{marginTop: '25px', width: '45%'}}
                                onClick={() => {
                                    window.location.href = '/';
                                }}>
                                Vissza
                            </Button>
                            <Button
                                style={{marginTop: '25px', width: '45%'}}
                                variant="contained"
                                color="primary"
                                onClick={activeStep === 1 ? handleConfirmationOpen : handleNext}
                                disabled={activeStep === 0 && !isStepOneComplete}
                            >
                                {activeStep === 1 ? 'Véglegesítés' : 'Következő'}
                            </Button>
                        </div>
                        <ConfirmationModal
                            open={openConfirmation}
                            onClose={handleConfirmationClose}
                            onConfirm={handleFinalize}
                            email={email}
                            phone={phone}
                            userHash={user ? user.user_hash : ''}
                        />
                    </>
                )}
            </Box>
        </Container>
    );
};

export default Sales;
