import React from 'react';

const DiscountList = ({ extraDiscounts }) => {
    if (!extraDiscounts || !Array.isArray(extraDiscounts) || extraDiscounts.length === 0) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '15px'}}>
                <div className="discountTab">
                    Extra kedvezmény:
                </div>
                <div className="discountTab">
                    Nem található extra kedvezmény!
                </div>
            </div>
        );
    }

    return (
        <div className="discountRow">
            <div className="discountTab">
                Extra kedvezmény:
            </div>
            <div className='discountRes'>
                {extraDiscounts.map((extraDiscount, index) => (
                    <p key={index}>{extraDiscount.text}: {extraDiscount.discount}%</p>
                ))}
            </div>
        </div>
    );
};

export default DiscountList;
