import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SchoolIcon from '@mui/icons-material/School';
import OptionContainer from '../components/Home/OptionContainer';
import CardOption from '../components/Home/CardOption';
import PaidIcon from '@mui/icons-material/Paid';
import PercentIcon from '@mui/icons-material/Percent';
import {useSelector} from 'react-redux';
import axios from 'axios';
import AppStoreImg from '../appstore.png'
import PlayStoreImg from '../playstore.png'

const Home = () => {
    const user = useSelector((state) => state.login.user[0]);
    const [isReseller, setIsReseller] = useState(false);
    const [hasAffiliateId, setHasAffiliateId] = useState(false);
    const [hasMedioId, setHasMedioId] = useState(false);
    const [isMedioOnly, setIsMedioOnly] = useState(false);
    const [medioCardReferrer, setMedioCardReferrer] = useState("")

    useEffect(() => {
        const fetchUserStatus = async () => {
            try {
                const response = await axios.get(`https://app.kvery.io/query/api/120da4fb5c6/check-reseller?hash=${user.user_hash}`);
                if (response.data && response.data.status === 1 && response.data.response.length > 0) {
                    const { is_reseller, has_affiliate_id, has_medio_id, medio_only, medio_card_referrer } = response.data.response[0];
                    setIsReseller(is_reseller === 1);
                    setHasAffiliateId(has_affiliate_id === 1);
                    setHasMedioId(has_medio_id === 1);
                    setIsMedioOnly(medio_only === 1); // Itt a helyes változó használata
                    setMedioCardReferrer(medio_card_referrer);
                    console.log(medioCardReferrer);
                    console.log(isMedioOnly);
                }
            } catch (error) {
                // Handle error from API call
            }
        };

        if (user && user.user_hash) {
            fetchUserStatus();
        }
    }, [user]);


    const openKnowledgeBase = async () => {
        let url = '';

        if (!hasMedioId && !isReseller) {
            url = 'https://sanitaskartya.hu/oktatas/ajanlo/';
        } else if (isReseller && hasAffiliateId && !hasMedioId) {
            url = 'https://sanitaskartya.hu/oktatas/ajanlo-es-ertekesito/';
        } else if (hasMedioId && !isReseller) {
            url = 'https://sanitaskartya.hu/oktatas/elfogadohely-es-ajanlo/';
        } else if (isReseller && hasAffiliateId && hasMedioId) {
            url = 'https://sanitaskartya.hu/oktatas/elfogadohely-es-ertekesito/';
        }
        window.open(url, '_blank');
    };

    return (
        <>
            <Container sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '25px',
                marginBottom: '25px',
                justifyContent: 'center'
            }}>
                <OptionContainer>
                    {!isMedioOnly && (
                        <CardOption
                            icon={<LocalOfferIcon style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minWidth: '25%',
                                fontSize: '35px',
                                color: '#3D5A80'
                            }}/>}
                            title="Kártya ajánlása"
                            onClick={() => {
                                window.location.href = '/proposal';
                            }}
                        />
                    )}
                    {(isReseller && hasAffiliateId) && (
                        <CardOption
                            icon={<PaidIcon style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minWidth: '25%',
                                fontSize: '35px',
                                color: '#3D5A80'
                            }}/>}
                            title="Értékesítés"
                            onClick={() => {
                                window.location.href = '/sales';
                            }}
                        />
                    )}
                    {isMedioOnly ? (
                        <>
                            <CardOption
                                icon={<CreditScoreIcon style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '25%',
                                    fontSize: '35px',
                                    color: '#3D5A80'
                                }}/>}
                                title="Kártyaszám ellenőrzés"
                                onClick={() => {
                                    window.location.href = '/check';
                                }}
                            />
                            <CardOption
                                icon={<PercentIcon style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '25%',
                                    fontSize: '35px',
                                    color: '#3D5A80'
                                }}/>}
                                title="Aktuális kedvezmények"
                                onClick={() => {
                                    window.location.href = '/current-discount';
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <CardOption
                                icon={<CreditScoreIcon style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '25%',
                                    fontSize: '35px',
                                    color: '#3D5A80'
                                }}/>}
                                title="Kártyaszám ellenőrzés"
                                onClick={() => {
                                    window.location.href = '/check';
                                }}
                            />
                            <CardOption
                                icon={<PercentIcon style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '25%',
                                    fontSize: '35px',
                                    color: '#3D5A80'
                                }}/>}
                                title="Aktuális kedvezmények"
                                onClick={() => {
                                    window.location.href = '/current-discount';
                                }}
                            />
                            <CardOption
                                icon={<SchoolIcon style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '25%',
                                    fontSize: '35px',
                                    color: '#3D5A80'
                                }}/>}
                                title="Tudásbázis"
                                onClick={openKnowledgeBase}
                            />
                            <CardOption
                                icon={<AssessmentIcon style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '25%',
                                    fontSize: '35px',
                                    color: '#3D5A80'
                                }}/>}
                                title="Statisztika"
                                onClick={() => {
                                    window.location.href = '/statistics';
                                }}
                            />
                        </>
                    )}
                        <>
                            <p style={{textAlign: 'center', fontSize: '18px', fontWeight: '500'}}>Töltse le mobil
                                applikációnkat!</p>
                            <div className='appIcons'>
                                <a href="https://apps.apple.com/hu/app/sanitas-partner/id6463730786?l=hu"
                                   target="_blank">
                                    <img style={{width: '200px'}} src={AppStoreImg} alt="App Store"/>
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=hu.sanitaspartner.app"
                                   target="_blank">
                                    <img style={{width: '200px'}} src={PlayStoreImg} alt="Play Store"/>
                                </a>
                            </div>
                        </>
                </OptionContainer>
            </Container>
        </>
    );
};

export default Home;
