import Container from "@mui/material/Container";
import React, {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import axios from "axios";
import SalesStatisticsTable from "../components/Statistics/SalesStatisticsTable";
import CardOption from "../components/Home/CardOption";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import OptionContainer from "../components/Home/OptionContainer";
import PaidIcon from '@mui/icons-material/Paid';
import OffersStatisticsTable from "../components/Statistics/OffersStatisticsTable";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const Statistics = () => {
    const user = useSelector((state) => state.login.user[0]);
    const [offers, setOffers] = useState([]);
    const [sales, setSales] = useState([]);
    const [activeTable, setActiveTable] = useState(null);

    const showSalesTable = () => setActiveTable('sales');
    const showOffersTable = () => setActiveTable('offers');
    const hideTables = () => setActiveTable(null); // Visszaállítja az aktív táblázatot null-ra


    useEffect(() => {
        const fetchStatistics = async () => {
            try {
                const response = await axios.get(`https://app.kvery.io/query/api/120da4fb5c6/stat?hash=${user.user_hash}`);
                if (response.data && response.data.status === 1) {
                    // Az adatok szétválogatása a type alapján
                    const offersData = response.data.response.filter(item => item.type === 'offer');
                    const salesData = response.data.response.filter(item => item.type === 'sell');

                    // Az adatok beállítása az állapotokban
                    setOffers(offersData);
                    setSales(salesData);
                }
            } catch (error) {
                console.log("Hiba történt", error);
            }
        };

        if (user && user.user_hash) {
            fetchStatistics();
        }
    }, [user]);


    return (
        <>
            <Container sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '25px',
                marginBottom: '25px',
                justifyContent: 'center',
                minHeight: '70vh'
            }}>
                {activeTable === null && (
                    <OptionContainer>
                        <CardOption
                            icon={<PaidIcon style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minWidth: '25%',
                                fontSize: '35px',
                                color: '#3D5A80'
                            }}/>}
                            title={`Korábbi értékesítések (${sales.length})`}
                            onClick={showSalesTable}
                        />
                        <CardOption
                            icon={<LocalOfferIcon style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minWidth: '25%',
                                fontSize: '35px',
                                color: '#3D5A80'
                            }}/>}
                            title={`Korábbi ajánlások (${offers.length})`}
                            onClick={showOffersTable}
                        />
                        <Button style={{width: '150px', marginTop: '30px'}} variant="outlined" color="primary"
                                onClick={() => {
                                    window.location.href = '/';
                                }}>Vissza</Button>
                    </OptionContainer>
                )}
                {/* Ha aktív táblázat van, csak az és a "Vissza" gomb jelenik meg */}
                {activeTable === 'sales' && (
                    <>
                        <Box style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '800px',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            justifyContent: 'center',
                            alignContent: 'center',
                            padding: '25px',
                        }}>
                            <SalesStatisticsTable data={sales}/>
                            <Button style={{width: '150px', marginTop: '60px'}} variant="outlined" color="primary"
                                    onClick={hideTables}>Vissza</Button>
                        </Box>
                    </>
                )}
                {activeTable === 'offers' && (
                    <>
                        <Box style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '800px',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            justifyContent: 'center',
                            alignContent: 'center',
                            padding: '25px',
                            height: '550px'
                        }}>
                            <OffersStatisticsTable data={offers}/>
                            <Button style={{width: '150px', marginTop: '60px'}} variant="outlined" color="primary"
                                    onClick={hideTables}>Vissza</Button>
                        </Box>
                    </>
                )}

            </Container>
        </>
    )
}

export default Statistics