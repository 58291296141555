import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";


const DiscountInfo = ({institutionData}) => {
    if (!institutionData || !institutionData.base_discount) {
        return <p>Betöltés...</p>;
    }

    return (
        <div style={{display: 'flex', width: '80%', flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {institutionData.image && <img className="institutionImage" src={institutionData.image} alt="Institution Image"/>}
            </div>
            <div className="discountRow">
                <div className="discountTab">
                    Alap kedvezmény:
                </div>
                <div className='discountRes'>
                    {institutionData.base_discount}%
                </div>
            </div>
            <div className="discountRow">
                <div className="discountTab">
                    További kedvezmények:
                </div>
                <div className='discountRes'>
                    <ul>
                        {institutionData.extra_discounts ? (
                            institutionData.extra_discounts.map((discount, index) => (
                                <li key={index}>{discount.text}: {discount.discount}%</li>
                            ))
                        ) : (
                            <li>Nincsenek további kedvezmények</li>
                        )}
                    </ul>
                </div>
            </div>

            <div className="discountRowLast">
                <div className="discountTab" style={{color: '#ED535C'}}>
                    Kivételek:
                </div>
                <div className='discountRes'>
                    {institutionData.club_exceptions}
                </div>
            </div>
        </div>
    )
        ;

}
const CurrentDiscount = () => {
    const user = useSelector((state) => state.login.user[0]);
    const [institutionData, setInstitutionData] = useState(null);
    const [institutionId, setInstitutionId] = useState('');

    const fetchMedioInstitutionId = useCallback(() => {
        fetch(`https://app.kvery.io/query/api/120da4fb5c6/check-medio-id?hash=${user.user_hash}`)
            .then((response) => response.json())
            .then((data) => {
                if (data?.status === 1 && data?.response?.length > 0) {
                    setInstitutionId(data.response[0].medio_id);
                } else {
                    setInstitutionId(null);
                }
            })
            .catch((error) => {
                console.error('Hiba történt a fetch közben:', error);
                setInstitutionData(null);
            });
    }, [user.user_hash]);

    useEffect(() => {
        fetchMedioInstitutionId();
    }, [fetchMedioInstitutionId]);

    const fetchInstitutionData = useCallback(() => {
        if (institutionId) {
            fetch(`https://app.kvery.io/query/api/120da4fb5c6/1690809051vHdP39z6XWIQGY8xZTqVxa?institutionId=${institutionId}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data?.status === 1 && data?.response?.length > 0) {
                        setInstitutionData(data.response[0]);
                    } else {
                        setInstitutionData(null);
                    }
                })
                .catch((error) => {
                    console.error('Hiba történt a fetch közben:', error);
                    setInstitutionData(null);
                });
        } else {
            setInstitutionData(null);
        }
    }, [institutionId]);

    useEffect(() => {
        fetchInstitutionData();
    }, [fetchInstitutionData]);


    console.log(institutionId)
    console.log(institutionData)


    return (
        <Container sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '25px',
            marginBottom: '25px',
            justifyContent: 'center',
            minHeight: '70vh'
        }}>
            <Box
                className='checkResult'
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    bgcolor: '#fff',
                    width: '550px',
                    minHeight: '350px',
                    borderRadius: '8px',
                    justifyContent: 'center',
                    alignContent: 'center',
                    padding: '25px'
                }}>
                <DiscountInfo institutionData={institutionData}/>
                <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button style={{marginTop: '25px', width: '45%'}} variant="outlined" onClick={() => {
                        window.location.href = '/';
                    }}>Vissza</Button>
                </Box>
            </Box>
        </Container>
    )
}

export default CurrentDiscount;