import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {MuiTelInput} from "mui-tel-input";

const StepOneContent = ({email, phone, coupon, onEmailChange, onPhoneChange, onCouponChange}) => {
    const lowercaseEmail = email.toLowerCase();


    return (
        <Box style={{display: 'flex', flexDirection: 'column', marginBottom: '20px'}}>
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>
                Kérlek add meg az ügyfél adatait:
            </p>
            <TextField
                id="standard-basic"
                helperText="E-mail cím"
                variant="standard"
                value={lowercaseEmail}
                onChange={onEmailChange}
            />
            <MuiTelInput
                style={{marginTop: '15px', padding: '0px'}}
                helperText="Telefonszám"
                value={phone}
                onChange={onPhoneChange}
                defaultCountry="HU"
            />
            <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems:'center'}}>
                <TextField
                    style={{justifyContent:'center',width:'200px'}}
                    id="coupon"
                    helperText="Kuponkód (nem kötelező)"
                    variant="standard"
                    value={coupon}
                    onChange={(e) => onCouponChange(e.target.value)}
                />
            </div>
        </Box>
    );
};

export default StepOneContent;
