import React, {useState} from "react";
import {DataGrid} from "@mui/x-data-grid";

const SalesStatisticsTable = ({data}) => {

    const columns = [
        {field: "id", headerName: "ID", flex: 1},
        {field: "email", headerName: "E-mail", flex: 1},
        {field: "phone", headerName: "Telefonszám", flex: 1},
    ];


    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleClickOpen = (rowData) => {
        setSelectedRow(rowData);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{height: 400, width: "100%"}}>
            <p className="boxTitle">Értékesítések:</p>
            <DataGrid rows={data} columns={columns} pageSize={10}/>
        </div>
    );
};

export default SalesStatisticsTable;
