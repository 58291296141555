import React from 'react'
import logo from "../sanitas_partner_white.svg";
import Box from '@mui/material/Box';

const Footer = () => {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', height: '10vh', justifyContent: 'center'}}>
            <img className="logo" src={logo} alt="Logo"/>
        </Box>
    )
}

export default Footer