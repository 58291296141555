import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { MuiTelInput } from "mui-tel-input";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

const Register = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [acceptanceId, setAcceptanceId] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isEmailRegistered, setIsEmailRegistered] = useState(false); // Új állapot az ismétlődő email címnek

  const handleChange = (newValue) => {
    setPhone(newValue);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setIsEmailRegistered(false); // Reseteljük az ismétlődő email állapotot, ha az email cím változik
  };

  const handleAcceptanceIdChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      setAcceptanceId(value);
    }
  };

  const handleNextClick = () => {
    if (
      !isValidEmail(email) ||
      !first_name ||
      !last_name ||
      !phone ||
      !acceptTerms
    ) {
      setIsError(true);
      return;
    }

    const formData = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: phone,
      medio_id: acceptanceId,
    };

    axios
      .post("https://app.kvery.io/query/api/120da4fb5c6/register", formData)
      .then((response) => {
        console.log("Response:", response);
        if (
          response.status === 409 &&
          response.data.response === "The given email already exists!"
        ) {
          setIsEmailRegistered(true);
        } else if (response.status === 200) {
          setIsSuccess(true);
          setIsError(false);
        } else {
          setIsError(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Itt ellenőrizheted az Axios hibaüzenetét
        if (error.response) {
          console.error("Response Data:", error.response.data);
          console.error("Response Status:", error.response.status);
        }
        setIsError(true);
      });
  };

  const isValidEmail = (email) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const handleCloseSnackbar = () => {
    setIsError(false);
  };

  const handleModalClose = () => {
    setIsSuccess(false);
    window.location.href = "/";
  };

  return (
    <>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "25px",
          marginBottom: "25px",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            bgcolor: "#fff",
            minHeight: "300px",
            width: "550px",
            borderRadius: "8px",
            justifyContent: "center",
            alignContent: "center",
            padding: "25px",
          }}
        >
          <p className="boxTitle">
            A regisztrációhoz töltse ki az alábbi mezőket:
          </p>
          <div
            style={{ display: "flex", displayDirection: "row", gap: "10px" }}
          >
            <TextField
              style={{ width: "50%" }}
              id="standard-basic"
              helperText="Vezetéknév"
              variant="standard"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              style={{ width: "50%" }}
              id="standard-basic"
              helperText="Keresztnév"
              variant="standard"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <TextField
            id="standard-basic"
            helperText="E-mail cím"
            variant="standard"
            value={email}
            onChange={handleEmailChange}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <MuiTelInput
                style={{ marginTop: "15px", padding: "0px", width: "100%" }}
                helperText="Telefonszám"
                value={phone}
                onChange={handleChange}
                defaultCountry="HU"
              />
              <TextField
                style={{ width: "50%" }}
                id="standard-basic"
                helperText="Elfogadóhelyi azonosító"
                variant="standard"
                value={acceptanceId}
                onChange={handleAcceptanceIdChange}
              />
            </div>
          </div>
          <FormControlLabel
            control={
              <Checkbox onChange={(e) => setAcceptTerms(e.target.checked)} />
            }
            label={
              <p>
                Elfogadom az{" "}
                <a
                  href="https://sanitaskartya.hu/adatvedelmi-tajekoztato/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Adatkezelési tájékoztatót
                </a>
              </p>
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{ marginTop: "25px", width: "45%" }}
              variant="outlined"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Vissza
            </Button>
            <Button
              style={{ marginTop: "25px", width: "45%" }}
              variant="contained"
              disabled={!acceptTerms}
              onClick={handleNextClick}
            >
              Regisztrálok
            </Button>
          </div>
        </Box>
      </Container>

      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
        >
          Valami hiba történt! Ellenőrizd a bevitt adatokat!
        </MuiAlert>
      </Snackbar>
      <Snackbar open={isEmailRegistered} onClose={handleCloseSnackbar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
        >
          Ez az email cím már szerepel az adatbázisban.
        </MuiAlert>
      </Snackbar>

      <Dialog open={isSuccess} onClose={handleModalClose}>
        <DialogTitle>Köszönjük a regisztrációt!</DialogTitle>
        <DialogContent>
          <DialogContentText>Most már bejelentkezhetsz!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary" autoFocus>
            Bejelentkezés
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Register;
