import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Lottie from 'react-lottie';
import CheckAnimation from '../../check_animation.json';
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";

const StepFourContent = ({email, phone, coupon}) => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: CheckAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    // Állapot az API válasz tárolására
    const [product, setProduct] = useState(null);
    const [couponData, setCouponData] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch kérés az API végpont felé a termékekhez
    useEffect(() => {
        axios.get('https://sanitaskartya.hu/wp-json/wc/v3/products/11', {
            auth: {
                username: 'ck_3635f6e152e75a6256a300359a1b4afcbbf216d9',
                password: 'cs_d05cae0e025478b5d35fa73cf3d108f69efabe93'
            }
        })
            .then(response => {
                setProduct(response.data);
                setLoading(false); // Betöltés befejeződött, állapot módosítása
            })
            .catch(error => {
                console.error(error);
                setLoading(false); // Betöltés befejeződött, állapot módosítása
            });
    }, []);

    // Fetch kérés az API végpont felé a kuponokhoz
    useEffect(() => {
        if (coupon) {
            axios.get(`https://sanitaskartya.hu/wp-json/wc/v3/coupons?code=${coupon}`, {
                auth: {
                    username: 'ck_3635f6e152e75a6256a300359a1b4afcbbf216d9',
                    password: 'cs_d05cae0e025478b5d35fa73cf3d108f69efabe93'
                }
            })
                .then(response => {
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        // Ha található a kupon, beállítjuk a couponData állapotot
                        setCouponData(response.data[0]);
                    } else {
                        // Ha nincs találat, üres értéket állítunk be
                        setCouponData(null);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            // Ha nincs coupon, üres értéket állítunk be
            setCouponData(null);
        }
    }, [coupon]);

    // Végleges ár kiszámítása
    const calculateFinalPrice = () => {
        if (!product) return "0";

        if (couponData) {
            const totalPrice = parseFloat(product.price);
            const discountAmount = parseFloat(couponData.amount);

            switch (couponData.discount_type) {
                case 'percent':
                case 'recurring_percent':
                    const percentDiscount = (totalPrice * (discountAmount / 100));
                    return (totalPrice - percentDiscount).toFixed(0);

                case 'fixed_cart':
                case 'fixed_product':
                case 'subscription_trials':
                case 'recurring_fee':
                    return (totalPrice - discountAmount).toFixed(0);

                default:
                    return totalPrice.toFixed(0);
            }
        } else {
            return parseFloat(product.price).toFixed(0);
        }
    };


    return (
        <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px'}}>
            <Lottie
                options={defaultOptions}
                height={150}
                width={150}
            />
            <p style={{textAlign: 'center'}}>
                Ellenőrizd, hogy az adatok helyesek-e!
            </p>
            <span style={{fontWeight: 'bold'}}>{email}</span>
            <span style={{fontWeight: 'bold', marginTop: '15px'}}>{phone}</span>

            {loading ? ( // Betöltés állapot ellenőrzése
                <div style={{textAlign: 'center', marginTop: '15px'}}>
                    <p>Kis türelmet</p>
                    <CircularProgress/>
                </div>
            ) : (
                <div>
                    {couponData ? (
                        <div style={{textAlign: 'center'}}>
                            <p>Kuponkód: {couponData.code}</p>
                            <p>Kedvezmény: {couponData.discount_type === 'percent' || couponData.discount_type === 'recurring_percent' ? parseInt(couponData.amount, 10) + '%' : `${couponData.amount} Ft`}</p>
                        </div>
                    ) : (
                        <></>
                    )}

                    {
                        product ? (
                        <div style={{textAlign: 'center'}} key={product.id}>
                            <p>{product.name}</p>
                            <p style={{fontWeight: 'bold'}}>Ügyfél által fizetendő: {calculateFinalPrice()} Ft</p>
                        </div>
                        ) : null
                    }
                </div>
            )}
        </Box>
    );
};

export default StepFourContent;
